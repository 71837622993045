@font-face {
    font-family: 'Allianz Neo Regular';
    src: url('fonts/AllianzNeoW04-Regular.woff2');
}

@font-face {
    font-family: 'Allianz Neo Light';
    src: url('fonts/AllianzNeoW04-Light.woff2');
}

@font-face {
    font-family: 'Allianz Neo Bold';
    src: url('fonts/AllianzNeoW04-Bold.woff2');
}

@font-face {
    font-family: 'Allianz Icons';
    src: url('fonts/Allianz-Icons.woff2');
}

html, body * {
    font-family: 'Allianz Neo Regular', sans-serif;
    color:#414141
}

h1{
    /* color: rgb(7,89,148); */
    color: #414141;
    font-size: 40px;
    line-height: 48px;
}

p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
}

b {
    font-family: 'Allianz Neo Bold', sans-serif;
}

.dataItem p:hover{
    /* color: #007ab3; */
    cursor: pointer;
    font-family: 'Allianz Neo Bold', sans-serif;
}

.mainbox{
    border-radius: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
    padding: 33px;
}

.searchInputs {
    display: flex;
    justify-content: center;
}

.search input {
    /* box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%); */
    border: 1px solid #ccc;
    border-radius: 0px;
    padding: 15px;
    height: 17px;
    width: 570px;
    outline: 0;
    border-width: 0 0 2px;
    border-color: #007ab3;
    font-family: 'Allianz Neo Regular', sans-serif;
    color: rgb(7,89,148);
    font-size: 16px;

}

.searchIcon {
    height: 60px;
    width: 50px;
    background-color: #fff;
    display:grid;
    place-items: center;
}

input:focus {
    outline: none;
    font-family: 'Allianz Neo Regular', sans-serif;
    color: #007ab3;
}

.searchIcon svg {
    font-size: 35px;
}


svg path {
    color: #007ab3;
}

.dataResults {
    margin-top: 5px;
    width:590px;
    max-height:200px;
    background-color: rgb(255, 255, 255);
    /* border: 1px solid #ccc; */
    /* box-shadow: rgba(0,0,0,0.2) 0px 0px 10px; */
    overflow:hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    margin: 10px 0px 0px 60px;
}

.dataResults::-webkit-scrollbar {
    /* display: none; */
}

.dataResults .dataItem {
    width: 100%;
    height: 33px;
    display: flex;
    align-items: center;
    color: #000;
}

.dataItem p {
    margin-left: 10px;
    font-size: 16px;
    line-height: 24px;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    /* background-color: lightgrey; */
    color: #007ab3;
    font-family: 'Allianz Neo Bold', sans-serif;
}

#clearBtn {
    cursor: pointer;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 15px;
    font-size: 14px;
    text-align: center;
  }

  .text-center{
    text-align: center;
  }

  .mt-60{
    margin-top: 60px;
  }

  .mb-10{
    margin-bottom: 10px;
  }

  /* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  div::-webkit-scrollbar-thumb {
    background: #007ab3; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  div::-webkit-scrollbar-thumb:hover {
    background: #414141; 
  }

  .c1{
    color: #F86200;
  }

  .c2 {
    color: #3DA556;
  }

  .c3 {
    color: #006192;
  }

  .showAll {
    text-align: right;
    margin-right: 50px;
    cursor: pointer;
  }


  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .search {
        margin-left: 10px;
        margin-right: 10px;
        width: -webkit-fill-available;
    }

    .search input {
        width: 98%;
        font-size: 13px;
    }

    .dataResults {
        width: 98%;
        font-size: 13px;
        margin: 10px 0px 0px -1px;
    }

    .dataItem p {
        margin-left: 10px;
        font-size: 13px;
        line-height: 14px;
    }

    .dataResults .dataItem {
        padding-bottom: 16px;
    }

    .showAll {
        font-size: 13px;
      }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .mainbox {
        max-width: 670px;
    }
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}